import {
    ClearAndSelectFilters,
    FilterCategories,
    FilterPaymentOptions,
    FilterSearch,
    Filters,
    FiltersReset,
    InventoryHeader,
    ListingBreadcrumb,
    PageSorting,
    Pagination,
    PromoBanner,
    SeoContent,
    StickyFilterActions,
    TotalVehicles,
    Trade360Widget,
    VehicleSorting,
} from '@sm360/components'
import { changeUrl, findSelectedHubCategory, getValidTerm, scrollToElementTop } from '@sm360/helpers'
import { getStorageValue, useDeviceType, useStorage, useTranslation } from '@sm360/hooks'
import { Button } from '@sm360/ui-library'
import PropTypes from 'prop-types'
import React from 'react'

import { ListingContext } from '../../configs/listing-view/state.manager'
import Vehicles from '../vehicles/Vehicles'

// TODO: Need a logic from Delta to remove this hard coded
const hubNameWithDefaultLeaseMethod = ['new', 'demo', 'neuf']

const ListingBravo = ({ packageVersion }) => {
    const {
        state: {
            promoBanner,
            filters,
            paymentOptions,
            vehicleCriteria,
            filterMetaData,
            hubName,
            urlParams,
            icomoon,
            dealerInfos: { lang, orgUnitName, orgUnitCity },
            vehicles,
            XMSConfig: { configuration },
            hubGroupCategories,
            sortConfigurations,
            pagination,
            seoContent,
            selectedHubCategory,
            pathUrlInfos,
            isFetching,
            tradeInWidget: { tradeInBeforePayments, tradeInAfterPayments, tradeInBeforeListing, tradeInAfterListing },
            isFetchingTradeInWidget,
        },
        getEmptyCriteria,
        addVehicleCriteria,
        dispatch,
    } = React.useContext(ListingContext)

    const {
        i18n: { language },
    } = useTranslation()

    const { device } = useDeviceType() || { device: '' }

    const [isFilterOpen, setIsFilterOpen] = React.useState(false)

    const [initialPaymentFilters, setInitialPaymentFilters] = React.useState(false)

    // TODO: Remove these default once we get value from Delta
    const defaultLeaseTerm = 48
    const defaultKMPlan = 16000

    const cashPurchaseMethod = { slug: 'cash', index: 0 }

    const leasePurchaseMethod = { slug: 'lease', index: 2 }

    // TODO: We should have this logic from delta which purchase method to use for which hub
    // Remove this condition once delta is ready
    const currentPurchaseMethod = hubNameWithDefaultLeaseMethod.includes(hubName) ? leasePurchaseMethod : cashPurchaseMethod

    const [selectedPurchaseMethod, setSelectedPurchaseMethod] = useStorage(
        'purchaseMethod',
        currentPurchaseMethod,
        'session',
        true
    )

    const [selectedPaymentOptions, setSelectedPaymentOptions] = useStorage('paymentOptions', {
        paymentOptionPurchaseMethod: 'cash',
    })

    const accountTradeIn = getStorageValue('tradeIn', 0, 'local')

    const baseClass = 'il-listingBravo'
    const activeClass = isFilterOpen && device !== 'desktop' ? '-open' : ''

    const { t } = useTranslation()

    const { filtersLayout } = configuration || {}

    const totalNoOfItems = pagination?.numberOfItems || 0

    React.useEffect(() => {
        if (paymentOptions?.length > 0 && !initialPaymentFilters) {
            if (
                (currentPurchaseMethod.index === 1 && !paymentOptions[0]?.isAvailable) ||
                (currentPurchaseMethod.index === 2 && !paymentOptions[1]?.isAvailable)
            ) {
                handlePurchaseMethodSelection('cash', 0)
            } else {
                handlePurchaseMethodSelection(currentPurchaseMethod?.slug, currentPurchaseMethod?.index)
            }
            setInitialPaymentFilters(true)
        }
    }, [paymentOptions])

    const initPaymentOptionsStorage = (purchaseMethod) => {
        const { terms: financeTerm, paymentFrequencies: financePaymentFrequencies } = paymentOptions[0] || {}

        const { terms: leaseTerm, paymentFrequencies: leasePaymentFrequencies } = paymentOptions[1] || {}

        switch (purchaseMethod) {
            case 'finance':
                setSelectedPaymentOptions({
                    paymentOptionPaymentFrequency: financePaymentFrequencies[0],
                    paymentOptionTerm: getValidTerm(financeTerm)?.[0],
                    paymentOptionPurchaseMethod: 'finance',
                })
                break
            case 'lease':
                setSelectedPaymentOptions({
                    paymentOptionPaymentFrequency: leasePaymentFrequencies[0],
                    paymentOptionTerm: leaseTerm?.includes(defaultLeaseTerm) ? defaultLeaseTerm : getValidTerm(leaseTerm)?.[0],
                    paymentOptionPurchaseMethod: 'lease',
                })
                break
            default:
                setSelectedPaymentOptions({
                    paymentOptionPurchaseMethod: 'cash',
                })
        }
    }

    const handlePurchaseMethodSelection = (selectedPurchaseMethod, index) => {
        if (typeof window !== 'undefined') {
            initPaymentOptionsStorage(selectedPurchaseMethod)
            setSelectedPurchaseMethod({ slug: selectedPurchaseMethod, index })
            dispatch({ type: 'updatePaymentInformation', paymentInformationUpdate: true })
        }
    }

    /**
     * Function to change default purchase method in filter payment option based on the selected hub name
     * TODO: For now we have lease payment for new and demo, and all other fall to be cash
     * This information should comes from Delta and remove the condition when delta is ready
     * @param {*} hubName
     */
    const updateDefaultPurchaseMethod = (hubName) => {
        if (hubName === 'new' || hubName === 'demo') {
            handlePurchaseMethodSelection('lease', 2)
        } else {
            handlePurchaseMethodSelection('cash', 0)
        }
    }

    const handlePaymentOptionsSelectionChange = (label, value) => {
        const newPaymentOption = { [label]: value }
        setSelectedPaymentOptions({
            ...selectedPaymentOptions,
            ...newPaymentOption,
        })
        dispatch({ type: 'updatePaymentInformation', paymentInformationUpdate: true })
    }

    const handleInputRange = (name, value) => {
        const criteria = addVehicleCriteria(vehicleCriteria, { [name]: value }, null, true)
        changeUrl(criteria, filterMetaData, language, hubName)
        // dispatch({ type: 'forceUpdate', forceUpdate: true })
        dispatch({ type: 'updateVehicleCriteria', vehicleCriteria: criteria })
    }

    const handleRadioChange = (value) => {
        const newCriteria = getEmptyCriteria(vehicleCriteria)
        const selectedHubCategory = findSelectedHubCategory(value, hubGroupCategories, language)
        changeUrl({}, [], language, value, true)
        updateDefaultPurchaseMethod(value)
        dispatch({ type: 'updateVehicleCriteria', vehicleCriteria: newCriteria })
        dispatch({ type: 'updateHubName', hubName: value, selectedHubCategory })
        // dispatch({ type: 'forceUpdate', forceUpdate: true })
    }

    const handleSortingChange = async (value) => {
        const criteria = addVehicleCriteria(vehicleCriteria, { namedSorting: value })
        // Update Vehicle Criteria State
        dispatch({ type: 'updateVehicleCriteria', vehicleCriteria: criteria })
    }

    /**
     * Handle Vehicle per Page Sorting Selected
     * @param { String } value - Selected value
     * @returns {Promise<void>}
     */
    const handlePageSorting = async (value) => {
        const criteria = addVehicleCriteria(vehicleCriteria, { limit: value })

        // Update Vehicle Criteria State
        dispatch({ type: 'updateVehicleCriteria', vehicleCriteria: criteria })
    }

    // Clear all Filters
    const handleClearAllFilters = () => {
        const newCriteria = getEmptyCriteria(vehicleCriteria)
        changeUrl({}, [], language, hubName, true)
        dispatch({ type: 'updateVehicleCriteria', vehicleCriteria: newCriteria })
        // dispatch({ type: 'forceUpdate', forceUpdate: true })
    }

    const handleFiltersButtonClick = () => {
        setIsFilterOpen(!isFilterOpen)
        if (!isFilterOpen) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }

    const handleOnPageChange = async (pageNumber) => {
        const criteria = addVehicleCriteria(vehicleCriteria, { page: pageNumber })
        // Change URL
        changeUrl(criteria, filterMetaData, language, hubName)
        // Update Vehicle Criteria State
        dispatch({ type: 'updateVehicleCriteria', vehicleCriteria: criteria })
        // Scroll To Page Top
        scrollToElementTop()
    }

    return (
        <main className={baseClass} data-reset-parents-overflow>
            {/* Section Top */}
            <div className={`${baseClass}__top`}>
                {/* Section Breadcrumb */}
                <section className={`${baseClass}__breadcrumb -wrapper`}>
                    <ListingBreadcrumb
                        filterMetaData={filterMetaData}
                        vehicleCriteria={vehicleCriteria}
                        selectedHubCategory={selectedHubCategory}
                        pathUrlInfos={pathUrlInfos}
                        extraClasses={`${baseClass}__breadcrumb`}
                    />
                </section>
                {/* Section Promo Banner */}
                <section className={`${baseClass}__promoBanner`}>
                    <PromoBanner promoList={promoBanner} />
                </section>
                {/* Section Introduction */}
                <section className={`${baseClass}__heading -wrapper`}>
                    <InventoryHeader
                        extraClasses={`${baseClass}__title`}
                        hubName={hubName}
                        vehicleCriteria={vehicleCriteria}
                        filters={filters}
                        orgUnitName={orgUnitName}
                        orgUnitCity={orgUnitCity}
                        seoContent={seoContent}
                        filterMetaData={filterMetaData}
                        hubCategories={hubGroupCategories}
                    />

                    {/* Section Filters */}
                    <FilterSearch
                        filterMetaData={filterMetaData}
                        vehicleCriteria={vehicleCriteria}
                        hubName={hubName}
                        addVehicleCriteria={addVehicleCriteria}
                        dispatch={dispatch}
                    />
                </section>
            </div>
            {/* Section Container */}
            <div className={`${baseClass}__container`}>
                {/* Mobile Filters Sticky */}
                {device !== 'desktop' && (
                    <StickyFilterActions
                        hubCategories={hubGroupCategories}
                        hubName={hubName}
                        vehicleCriteria={vehicleCriteria}
                        vehicles={vehicles}
                        sortConfigurations={sortConfigurations}
                        handleCategoryChange={(value) => handleRadioChange(value)}
                        handleSortingChange={(value) => handleSortingChange(value)}
                        handleFiltersButtonClick={handleFiltersButtonClick}
                    />
                )}

                {/* Section Sidebar */}
                <section className={`${baseClass}__sidebar ${activeClass}`}>
                    {/* Show if filter layout is horizontal */}
                    {filtersLayout === 'Horizontal' && device === 'desktop' && (
                        <FilterCategories
                            extraClasses={`${baseClass}__filterCategories`}
                            handleChange={(value) => handleRadioChange(value)}
                            hubCategories={hubGroupCategories}
                            hubName={hubName}
                        />
                    )}

                    <div className={`${baseClass}__verticalFilters`}>
                        <Button className={`${baseClass}__closeButton`} label='x' onClick={handleFiltersButtonClick} />

                        {/* Widget TradeIn360 Before Payments */}
                        {device && tradeInBeforePayments && (
                            <Trade360Widget
                                extraClasses={`${baseClass}__trade360`}
                                widgetId={'tradeInBeforePayments'}
                                tradeInWidget={tradeInBeforePayments}
                                elementNodeName={'data-trade-standalone'}
                                elementNodeValue={'tradeInBeforePayments'}
                                isFetching={isFetchingTradeInWidget}
                            />
                        )}

                        {/* Payment options filter */}
                        <FilterPaymentOptions
                            filters={filters}
                            paymentOptions={paymentOptions}
                            onPurchaseMethodSelected={handlePurchaseMethodSelection}
                            selectedPurchaseMethodIndex={selectedPurchaseMethod?.index}
                            onPaymentOptionsSelectionChange={handlePaymentOptionsSelectionChange}
                            selectedPaymentOptions={selectedPaymentOptions}
                            handleInputRange={handleInputRange}
                            vehicleCriteria={vehicleCriteria}
                            accountTradeIn={accountTradeIn}
                            lang={lang}
                        />

                        {/* Widget TradeIn360 After Payments */}
                        {device && tradeInAfterPayments && (
                            <Trade360Widget
                                extraClasses={`${baseClass}__trade360`}
                                widgetId={'tradeInAfterPayments'}
                                tradeInWidget={tradeInAfterPayments}
                                elementNodeName={'data-trade-standalone'}
                                elementNodeValue={'tradeInAfterPayments'}
                                isFetching={isFetchingTradeInWidget}
                            />
                        )}

                        {/** if filter layout is vertical or mobile display <filter> */}
                        {(filtersLayout === 'Vertical' || device !== 'desktop') && (
                            <Filters
                                filterMetaData={filterMetaData}
                                vehicleCriteria={vehicleCriteria}
                                hubName={hubName}
                                filters={filters}
                                vehicles={vehicles}
                                icomoon={icomoon}
                                addVehicleCriteria={addVehicleCriteria}
                                changeUrl={changeUrl}
                                dispatch={dispatch}
                                layout={filtersLayout}
                                deviceType={device}
                            />
                        )}

                        {device !== 'desktop' && (
                            <div className={`${baseClass}__filterFooter`}>
                                <ClearAndSelectFilters
                                    extraClasses={`${baseClass}__clearFilters`}
                                    handleClearFilters={handleClearAllFilters}
                                    showClearFilter={true}
                                    showSelectAll={false}
                                />

                                <Button
                                    className={`${baseClass}__totalNoOfItems`}
                                    label={t('filters.footerButton', { totalNoOfItems })}
                                    displayAsBlock
                                    onClick={handleFiltersButtonClick}
                                />
                            </div>
                        )}
                    </div>
                </section>

                {/* Section Main Content */}
                <section className={`${baseClass}__mainContent`}>
                    {/* Show if filter layout is vertical */}
                    {filtersLayout === 'Vertical' && device === 'desktop' && (
                        <FilterCategories
                            extraClasses={`${baseClass}__filterCategories`}
                            handleChange={(value) => handleRadioChange(value)}
                            hubCategories={hubGroupCategories}
                            hubName={hubName}
                            position='horizontal'
                        />
                    )}
                    {/** if filtersLayout is horizontal and device is desktop then display <Filter> */}

                    {filtersLayout === 'Horizontal' && device === 'desktop' && (
                        <Filters
                            filterMetaData={filterMetaData}
                            vehicleCriteria={vehicleCriteria}
                            hubName={hubName}
                            filters={filters}
                            vehicles={vehicles}
                            icomoon={icomoon}
                            addVehicleCriteria={addVehicleCriteria}
                            changeUrl={changeUrl}
                            dispatch={dispatch}
                            layout={filtersLayout}
                            deviceType={device}
                        />
                    )}

                    <FiltersReset
                        extraClasses={`${baseClass}__filtersReset`}
                        filterMetaData={filterMetaData}
                        vehicleCriteria={vehicleCriteria}
                        locale={lang}
                        hubName={hubName}
                        addVehicleCriteria={addVehicleCriteria}
                        getEmptyCriteria={getEmptyCriteria}
                        dispatch={dispatch}
                    />

                    <div className={`${baseClass}__vehicleSortSection`}>
                        <TotalVehicles
                            extraClasses={`${baseClass}__totalVehicles`}
                            totalVehicles={pagination?.numberOfItems}
                            loading={isFetching}
                        />

                        <div className={`${baseClass}__vehicleAndPageSorting`}>
                            <VehicleSorting
                                extraClasses={`${baseClass}__vehicleSorting`}
                                sortConfigurations={sortConfigurations}
                                vehicles={vehicles}
                                hubName={hubName}
                                vehicleCriteria={vehicleCriteria}
                                handleChange={(value) => handleSortingChange(value)}
                            />
                            <PageSorting
                                extraClasses={`${baseClass}__pageSorting`}
                                limitParams={urlParams.limit}
                                pagination={pagination}
                                handleChange={(value) => handlePageSorting(value)}
                            />
                        </div>
                    </div>

                    {/* Widget TradeIn360 Before Listing */}
                    {device && tradeInBeforeListing && (
                        <Trade360Widget
                            extraClasses={`${baseClass}__trade360`}
                            widgetId={'tradeInBeforeListing'}
                            tradeInWidget={tradeInBeforeListing}
                            elementNodeName={'data-trade-standalone'}
                            elementNodeValue={'tradeInBeforeListing'}
                            isFetching={isFetchingTradeInWidget}
                        />
                    )}

                    <Vehicles extraClasses={`${baseClass}__vehicles`} packageVersion={packageVersion} />

                    <Pagination
                        extraClasses={`${baseClass}__pagination`}
                        handleClick={(currentPage) => handleOnPageChange(currentPage)}
                        pagination={pagination}
                    />

                    {/* Widget TradeIn360 After Listing */}
                    {device && tradeInAfterListing && (
                        <Trade360Widget
                            extraClasses={`${baseClass}__trade360`}
                            widgetId={'tradeInAfterListing'}
                            tradeInWidget={tradeInAfterListing}
                            elementNodeName={'data-trade-standalone'}
                            elementNodeValue={'tradeInAfterListing'}
                            isFetching={isFetchingTradeInWidget}
                        />
                    )}
                </section>
            </div>
            <div className={`${baseClass}__footer`}>
                <section className={`${baseClass}__footerContent`}>
                    <SeoContent
                        hubName={hubName}
                        vehicleCriteria={vehicleCriteria}
                        filters={filters}
                        seoContent={seoContent}
                        orgUnitName={orgUnitName}
                        orgUnitCity={orgUnitCity}
                        filterMetaData={filterMetaData}
                        hubCategories={hubGroupCategories}
                    />
                </section>
            </div>
            <div className={`${baseClass}__calculator`} data-standalone='paymentCalculator'></div>
        </main>
    )
}

ListingBravo.propTypes = {
    /** Package version to display */
    packageVersion: PropTypes.string,
}

ListingBravo.defaultProps = {
    packageVersion: '',
}

export default ListingBravo
