import { useSsr, useTranslation } from '@sm360/hooks'
import { Anchor } from '@sm360/ui-library'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

import { ListingContext } from '../../../../configs/listing-view/state.manager'
import { VehicleCardContext } from '../../../../configs/vehicle-card-view/state.manager'
import { shopClickDrive } from '../../../../third-parties/thirdParties'
import { generateVehicleFormUrl } from '../../../../utils/global-functions.utils'
import { isExternalUrl } from '../../../../utils/handle-url.utils'
import { electricModelSlug } from '../../../../constants/electricModelSlug'

const VehicleCtas = ({ extraClasses }) => {
    const {
        state: {
            vehicleCtas,
            vehicle,
            vehicle: { newVehicle, year, make, model, vehicleId, serialNo },
            dealerInfos: { domain },
        },
    } = React.useContext(VehicleCardContext)

    const {
        state: {
            hubName,
            dealerInfos: { lang, dealerBac, dealerCode, orgUnitMakes },
            isHumberview,
        },
    } = React.useContext(ListingContext)

    const { t } = useTranslation()

    const { isBrowser } = useSsr()

    const newCtas = vehicleCtas?.filter(({ active, statusName }) => active && statusName === 'new')
    const usedCtas = vehicleCtas?.filter(({ active, statusName }) => active && statusName === 'used')

    const ctas = newVehicle ? newCtas : usedCtas

    const isGroup = hubName.includes('group')

    let categoryName = 'used'

    if (isGroup) {
        if (newVehicle) categoryName = 'group.new'
        else categoryName = 'group.used'
    } else if (newVehicle) categoryName = 'new'

    const baseClass = 'vc-vehicleCtas'
    const classNames = clsx(baseClass, extraClasses)

    // Condition to check if the model is electric and from Ford only
    const isElectricFord =
        electricModelSlug.includes(model?.slug) &&
        orgUnitMakes?.length === 1 &&
        orgUnitMakes[0] === 'ford' &&
        newVehicle &&
        !isHumberview

    if (!ctas?.length) return null

    return (
        <div className={classNames}>
            {ctas.map(({ buttonLabel, buttonLink, version, slug, id, picto }) => {
                const commonProps = {
                    key: id,
                    className: `${baseClass}__anchor ${picto ? 'm-buttonWithIcon' : 'a-button'}  -${version}`,
                    label: buttonLabel,
                    title: buttonLabel,
                    underlined: false,
                    displayAsButton: true,
                    icon: picto,
                }

                switch (slug) {
                    case 'see_details':
                        return (
                            <Anchor
                                {...commonProps}
                                title={t('vehicle.actions.details.title', { year, make: make?.slug, model: model?.slug })}
                                to={t(`vehicle.details.${categoryName}`, {
                                    year,
                                    make: make?.slug,
                                    model: encodeURIComponent(model?.slug),
                                    vehicleId,
                                })}
                            />
                        )
                    case 'moto_insight':
                        return (
                            <Anchor
                                {...commonProps}
                                className={`${baseClass}__anchor motocommerce-vehicle-link a-button -${version}`}
                                to=''
                                target='_blank'
                                data-stock-id={serialNo}
                            />
                        )
                    case 'eshop':
                        return (
                            <div className={`${baseClass}__anchor`}>
                                <app-eshop
                                    key={id}
                                    vin={serialNo}
                                    dealercode={dealerCode}
                                    vehicletype={newVehicle ? 'New' : 'preowned'}
                                    page='TradeIn'
                                    language={lang.toUpperCase()}
                                />
                            </div>
                        )
                    case 'shop_click_drive':
                        const shopClickDriveUrl = dealerBac && shopClickDrive(make?.slug, dealerBac, serialNo, lang)

                        if (!shopClickDriveUrl) return null

                        return <Anchor {...commonProps} to={shopClickDriveUrl} target='_blank' />
                    case 'roadster':
                        const roadsterUrl = `${buttonLink}/${serialNo}?locale=${lang}-ca`

                        return <Anchor {...commonProps} to={roadsterUrl} target='_blank' />
                    case 'form_page':
                        const vehicleFormUrl = generateVehicleFormUrl(vehicle, 'inventory', buttonLink, isBrowser, domain)

                        return <Anchor {...commonProps} to={vehicleFormUrl} />
                    case 'buy_now':
                        if (!isElectricFord) return null
                        const buyNowUrl = `${buttonLink}/${serialNo}?dealerCode=${dealerCode}`

                        return <Anchor {...commonProps} to={buyNowUrl} target={isExternalUrl(buttonLink) ? '_blank' : '_self'} />
                    default:
                        return <Anchor {...commonProps} to={buttonLink} />
                }
            })}
        </div>
    )
}

VehicleCtas.propTypes = {
    /** Extra Classes */
    extraClasses: PropTypes.string,
}

VehicleCtas.defaultProps = {
    extraClasses: '',
}

export default VehicleCtas
