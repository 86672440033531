import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { Select } from '@sm360/ui-library'
import { useTranslation } from '@sm360/hooks'

import { ListingContext } from '../../configs/listing-view/state.manager'
import ga4Trigger from '../../datalayer/analytics.service'
import dataLayerTags from '../../constants/dataLayerTags'
import { isArray } from '../../utils/helper.utils'

const VehicleSorting = ({ extraClasses }) => {
    const {
        state: { sortConfigurations, urlParams, vehicleCriteria },
        dispatch,
        addVehicleCriteria,
    } = React.useContext(ListingContext)

    const {
        t,
        i18n: { language },
    } = useTranslation()

    const [sortOptions, setSortOptions] = React.useState([])
    const [defaultValue, setDefaultValue] = React.useState('')

    React.useEffect(() => {
        if (sortConfigurations && sortConfigurations.length > 0) {
            setSortOptions(sortConfigurations)
            setDefaultValue(sortConfigurations[0]?.code)
        }
    }, [sortConfigurations])

    React.useEffect(() => {
        if (vehicleCriteria.namedSorting) {
            setDefaultValue(vehicleCriteria.namedSorting)
        }
    }, [vehicleCriteria])

    const baseClass = 'il-vehicleSorting'
    const classNames = clsx(baseClass, extraClasses)

    const triggerAnalytics = (label) => {
        ga4Trigger(dataLayerTags.FILTER_SORT_BY(label))
    }

    /**
     * Handle Vehicle Sorting Selected
     * @param { String } value - Selected value
     * @returns {Promise<void>}
     */
    const handleChange = async (value, label) => {
        const criteria = addVehicleCriteria(vehicleCriteria, { namedSorting: value })

        // Update Vehicle Criteria State
        dispatch({ type: 'updateVehicleCriteria', vehicleCriteria: criteria })
        triggerAnalytics(label)
    }

    if (!isArray(sortOptions)) {
        return null
    }

    const options = sortOptions.map(({ label, code }) => ({
        value: code,
        label,
    }))

    return (
        <div className={classNames}>
            <label htmlFor='vehicleSorting' className={`${baseClass}__label`}>
                {t('filters.sortedBy')}
            </label>

            <Select
                id='vehicleSorting'
                name='vehicleSorting'
                className={`${baseClass}__select`}
                options={options}
                value={urlParams.namedSorting || defaultValue}
                onChange={(value, name, { label }) => handleChange(value, label)}
            />
        </div>
    )
}

VehicleSorting.propTypes = {
    /** Extra Classes */
    extraClasses: PropTypes.string,
}

VehicleSorting.defaultProps = {
    extraClasses: '',
}

export default VehicleSorting
