import { CallForPricing, VehicleTagline } from '@sm360/components'
import { getStorageValue, useTranslation } from '@sm360/hooks'
import { Card, CardFooter, CardHeader, CardSection } from '@sm360/ui-library'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import LazyLoad from 'react-lazyload'

import StructuredData from '../../components/structured-data/StructuredData'
import CarfaxBadges from '../../components/vehicle-cards/components/carfax-badges/CarfaxBadges'
import CompareButton from '../../components/vehicle-cards/components/compare-button/CompareButton'
import DealerName from '../../components/vehicle-cards/components/dealer-name/DealerName'
import LocationAlert from '../../components/vehicle-cards/components/location-alert/LocationAlert'
import PaymentOptions from '../../components/vehicle-cards/components/payment-options/PaymentOptions'
import RecentViews from '../../components/vehicle-cards/components/recent-views/RecentViews'
import SerialNumber from '../../components/vehicle-cards/components/serial-number/SerialNumber'
import StockNumber from '../../components/vehicle-cards/components/stock-number/StockNumber'
import VehicleCertified from '../../components/vehicle-cards/components/vehicle-certified/VehicleCertified'
import VehicleConditions from '../../components/vehicle-cards/components/vehicle-conditions/VehicleConditions'
import VehicleCtas from '../../components/vehicle-cards/components/vehicle-ctas/VehicleCtas'
import VehicleHighlights from '../../components/vehicle-cards/components/vehicle-highlights/VehicleHighlights'
import VehicleImage from '../../components/vehicle-cards/components/vehicle-image/VehicleImage'
import VehicleName from '../../components/vehicle-cards/components/vehicle-name/VehicleName'
import VehicleRebates from '../../components/vehicle-cards/components/vehicle-rebates/VehicleRebates'
import VehicleSpecs from '../../components/vehicle-cards/components/vehicle-specs/VehicleSpecs'
import VehicleStatus from '../../components/vehicle-cards/components/vehicle-status/VehicleStatus'
import { ListingContext } from '../../configs/listing-view/state.manager'
import { VehicleCardContext } from '../../configs/vehicle-card-view/state.manager'
import dataLayerTags from '../../constants/dataLayerTags'
import ga4Trigger from '../../datalayer/analytics.service'
import getStructuredData from '../../structured-data/vehicleSchema'
import { WindowProxy, getPathname, isEmptyObject } from '../../utils/helper.utils'
import { getImageUrl } from '../../utils/image-url-generator.utils'

const VehicleCardView = ({ extraClasses, ...extraParams }) => {
    const {
        state: {
            XMSConfig: {
                pictureConfiguration: { newVehiclePlaceholder, usedVehiclePlaceholder },
                callForPriceThreshold,
                tripleMathBoxConfiguration,
            },
            vehicle,
            vehicle: { newVehicle, year, make, model, vehicleId, hasPrice, tagline, paymentOptions },
            dealerInfos: { domain },
            isHumberview,
        },
    } = React.useContext(VehicleCardContext)

    const {
        state: { hubName, showVehicleTagline },
    } = React.useContext(ListingContext)

    const { t } = useTranslation()

    const isGroup = hubName.includes('group')

    let categoryName = 'used'

    if (!isGroup) {
        if (newVehicle) categoryName = 'new'
    } else {
        if (newVehicle) categoryName = 'group.new'
        else categoryName = 'group.used'
    }

    const baseClass = 'vc-alpha'
    const classNames = clsx(baseClass, extraClasses)

    // TODO: Delete after callForPricing is done into XMS Delta
    const showCallForPricing = !hasPrice || paymentOptions?.cashPurchase.sellingPrice <= callForPriceThreshold

    const showTagline = showVehicleTagline === 'true'

    const { priceRounding } = tripleMathBoxConfiguration

    const triggerAnalytics = () => {
        ga4Trigger(dataLayerTags.MOST_POPULAR_VEHICLES(getPathname(), model?.name))
    }

    if (isEmptyObject(vehicle)) return null

    const noPhoto = newVehicle ? newVehiclePlaceholder : usedVehiclePlaceholder

    const vehicleVdpUrl =
        (domain || (WindowProxy && WindowProxy.location.href) || '') +
        t(`vehicle.details.${categoryName}`, {
            year,
            make: make?.slug,
            model: model?.slug,
            vehicleId,
        })

    const structuredData = getStructuredData({
        ...vehicle,
        vehicleVdpUrl,
        noPhoto,
        vehicleName: t('vehicle.name.yearMakeModel', { year, make: make?.name, model: model?.name }),
        vehicleImageUrl: vehicle?.multimedia?.mainPictureCompleteUrl || getImageUrl(noPhoto, 'w400h300c', 'web'),
    })

    const purchaseMethod = typeof window !== 'undefined' ? getStorageValue('purchaseMethod') : {}

    return (
        <Card className={classNames} {...extraParams}>
            <StructuredData data={structuredData} />
            <CardHeader className={`${baseClass}__header`}>
                <a
                    href={t(`vehicle.details.${categoryName}`, {
                        year,
                        make: make?.slug,
                        model: encodeURIComponent(model?.slug),
                        vehicleId,
                    })}
                    className={`${baseClass}__image`}
                    title={`${year} ${make?.name} ${model?.name}`}
                    onClick={triggerAnalytics}
                >
                    <LazyLoad height={200}>
                        <VehicleImage />
                    </LazyLoad>
                </a>
                {/* TODO: make this dynamic once vehicle object has the data to display the location alert banner */}

                <CompareButton extraClasses={`${baseClass}__compare`} />

                <VehicleStatus extraClasses={`${baseClass}__status`} />

                <RecentViews extraClasses={`${baseClass}__recentView`} />

                <VehicleRebates extraClasses={`${baseClass}__rebates`} priceRounding={priceRounding} />
            </CardHeader>

            <div className={`${baseClass}__placeholder`}>
                <LocationAlert extraClasses={`${baseClass}__locationAlert`} />
            </div>

            <CardSection className={`${baseClass}__section`}>
                <VehicleHighlights extraClasses={`${baseClass}__highlights`} />

                <div className={`${baseClass}__infos`}>
                    <div className={`${baseClass}__infosLeft`}>
                        <VehicleName extraClasses={`${baseClass}__name`} />
                    </div>
                    <div className={`${baseClass}__infosRight`}>
                        {isHumberview && newVehicle && <SerialNumber extraClasses={`${baseClass}__serialNumber`} />}
                        {!isHumberview && <SerialNumber extraClasses={`${baseClass}__serialNumber`} />}
                        <StockNumber extraClasses={`${baseClass}__stockNumber`} />
                    </div>
                </div>

                <DealerName className={`${baseClass}__dealerName`} />

                {showTagline && <VehicleTagline extraClasses={`${baseClass}__tagline`} tagline={tagline} clampLines={1} />}

                <VehicleSpecs extraClasses={`${baseClass}__specs`} isHumberview={isHumberview} />

                <div className={`${baseClass}__certifiedConditions`}>
                    <VehicleConditions extraClasses={`${baseClass}__conditions`} />

                    <VehicleCertified extraClasses={`${baseClass}__certified`} />
                </div>

                <CarfaxBadges extraClasses={`${baseClass}__carfaxBadges`} />

                {showCallForPricing && <CallForPricing />}

                {!showCallForPricing && (
                    <PaymentOptions extraClasses={`${baseClass}__paymentOptions`} purchaseMethod={purchaseMethod?.slug} />
                )}
            </CardSection>

            <CardFooter className={`${baseClass}__footer`}>
                <VehicleCtas extraClasses={`${baseClass}__ctas`} />
            </CardFooter>
        </Card>
    )
}

VehicleCardView.propTypes = {
    /** Extra Classes */
    extraClasses: PropTypes.string,
}

VehicleCardView.defaultProps = {
    extraClasses: '',
}

export default VehicleCardView
